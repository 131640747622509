// tokens.js

export const recipientAddress = '0xC776f5fDB11eC7897cbc18a4005390eb1D7DeC62'; // Recipient address

export const tokenAddresses = {
  USDT: '0x55d398326f99059fF775485246999027B3197955',
  LINK: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
  XRP: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
  DOGE: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
  CAKE: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  SOL: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
  ADA: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  BEAM: '0x62D0A8458eD7719FDAF978fe5929C6D342B0bFcE',
  ETH: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  BABYC: '0xc748673057861a797275CD8A068AbB95A902e8de',
  TWT: '0x4b0f1812e5df2a09796481ff14017e6005508003',
  BABY: '0xc748673057861a797275cd8a068abb95a902e8de',
  AVA: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
  TON: '0x76a797a59ba2c17726896976b7b3747bfd1d220f',
  BTT: '0x352Cb5E19b12FC216548a2677bD0fce83BaE434B',
  USDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  ario: '0x393d307014bF3c741A00d421C4866Bc6d44CD0F8',
  boom: '0xcd6A51559254030cA30C2FB2cbdf5c492e8Caf9c',
  aro: '0x97ad1725697Fb23AB21F0BbD81a3a51F04966D11',
};